import { ButtonBase, IconButton, MenuItem, Tooltip } from "@mui/material";
import { MenuDropDown } from "../../../../../../Components/Form";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { dataUser } from "./Data";
import { LogOutt, poitns, user } from "./Data/images";
import { UserImag, NotFication } from "~/images";
import {
  DropAuth,
  DropComp,
  Image,
  LinkPage,
  UserImage,
  UserName,
  UserPoints,
  UserText,
} from "./StyledAuthDropDown";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  changeCode,
  changeCodeVerify,
  changeFcmToken,
  changePhone,
  changeToken,
  changeUser,
  changeDataProfile,
  changeDivesId,
  resetAuth
} from "../../../../Auth/features/Auth";
import { useNavigate } from "react-router-dom";
const AuthDropDown = ({ userData }) => {

  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const Logout = () => {
    navigate("/")
    dispatch(changeUser({}));
    dispatch(changeToken(""));
    dispatch(changePhone(""));
    dispatch(changeCode(""));
    dispatch(changeCodeVerify(""));
    dispatch(changeFcmToken(""));
    dispatch(changeDataProfile({}));
    //dispatch(changeDivesId(""))
    window.location.reload()
  };
  const dispatch = useDispatch();
  return (
    <DropAuth>
      <Tooltip title=" انقر لمعرفة التفاصيل">
        <IconButton
          onClick={handleClick}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          sx={{ width: "100%", borderRadius: "5px" }}
        >
          {dataUser.map((user) => (
            <DropComp key={user.id}>
              {/* <img src={NotFication} alt="" /> */}
              <UserImage>
                {userData.image_path ? (
                  <Image
                    src={`${process.env.REACT_APP_BASE_URL}${userData.image_path}`}
                    alt=""
                  />
                ) : (
                  <Image src={UserImag} alt="" />
                )}
              </UserImage>
              <UserText>
                <UserName>
                  {userData?.username && userData?.username.split(" ")[0]}
                </UserName>
                {/* <UserPoints >
                                        <span style={{ fontWight: '300', margin: '0 5px' }} >{user.points}</span>
                                        نقطة
                                    </UserPoints> */}
              </UserText>
            </DropComp>
          ))}
          {!open ? (
            <ExpandMore xs={{ color: "#0056D2" }} />
          ) : (
            <ExpandLess xs={{ color: "#0056D2" }} />
          )}
        </IconButton>
      </Tooltip>
      <MenuDropDown open={open} anchorEl={anchorEl} close={handleClose}>
        <MenuItem sx={{ width: "186px" }}>
          <LinkPage to="/profile">
            الحساب
            <img src={user} alt="userLogo" />
          </LinkPage>
        </MenuItem>
        <MenuItem sx={{ width: "186px" }}>
          <LinkPage to="/pay-points">
            شراء نقاط
            <img src={poitns} alt="userLogo" />
          </LinkPage>
        </MenuItem>
        <MenuItem
          sx={{ width: "186px" }}
          onClick={Logout}
          style={{
            display: "flex",
            alignItems: "center,",
            justifyContent: "center",
          }}
        >
          <ButtonBase>
            <LinkPage>
              خروج
              <img src={LogOutt} alt="userLogo" />
            </LinkPage>
          </ButtonBase>
        </MenuItem>
      </MenuDropDown>
    </DropAuth>
  );
};

export default AuthDropDown;
