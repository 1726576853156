import * as Style from "./Styled";
import * as images from "~/images";
import * as FormComp from "~/Components/Form";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "../../../../../Provider/ModalProvider";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCode,
  changeCodeVerify,
  changeNewDivesId,
  changePhone,
  changeFcmToken,
  changeToken,
  changeUser,
  changeDataProfile,
  changeDivesId,
} from "../../features/Auth";
import { POST_REQUEST } from "../../../../../HooksApi/useApi";
import { USeInsertData } from "../../../../../HookAxios/postData";
const AprroveConfirmtion = () => {
  const dispatch = useDispatch();
  const { handleCloseApprove } = useModalContext();
  const navigate = useNavigate();
  const Logout = () => {
    navigate("/");
    dispatch(changeUser({}));
    dispatch(changeToken(""));
    dispatch(changePhone(""));
    dispatch(changeCode(""));
    dispatch(changeCodeVerify(""));
    dispatch(changeFcmToken(""));
    dispatch(changeDataProfile({}));
    //dispatch(changeDivesId(""));
    window.location.reload();
  };
  const token = useSelector((state) => state.Auth.userInfo.token);
  const divesNewId = useSelector((state) => state.Auth.userInfo.divesNewId);
  const divesId = useSelector((state) => state.Auth.userInfo.divesId);

  const {
    mutate: ApproveRequest,
    status: StatusApproveRequest,
    data: dataApproveRequest,
    error: errorApproveRequest,
  } = POST_REQUEST({
    request: (data) =>
      USeInsertData("verfiy_new_device", data, {
        Authorization: `Bearer ${token}`,
      }),
    handelOnSettledError,
    //handelOnSettledData,
  });
  function handelOnSettledError(error) {
    // setStatus(false)
    // setError(true)
    // setTimeout(() => {
    //   setError(false)
    // }, 5000);
  }
  // function handelOnSettledData(data) {
  //   dispatch(changeNewDivesId(""));
  // }

  const onSubmit = () => {
    ApproveRequest({ current_device_id: divesId, new_device_id: divesNewId });
  };
  return (
    <Style.MsgParents>
      <Style.Image src={images.Lock} />
      <Style.TextMassage>هناك عملية تسجيل دخول من جهاز اخر</Style.TextMassage>
      <FormComp.MuiButton
        text="تأكيد"
        width="200px"
        onClick={() => [
          navigate(`/`),
          handleCloseApprove(),
          onSubmit(),
          Logout(),
        ]}
      />
      <FormComp.MuiButton
        text="الغاء"
        width="200px"
        onClick={() => [handleCloseApprove()]}
      />
    </Style.MsgParents>
  );
};

export default AprroveConfirmtion;
