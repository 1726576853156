import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { api } from "../Services/Api/createApi";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCode,
  changeCodeVerify,
  changeDataProfile,
  changeDivesId,
  changeFcmToken,
  changePhone,
  changeToken,
  changeUser,
} from "../app/features/Auth/features/Auth";
import toast, { Toaster } from "react-hot-toast";

const getVerifyDeviceQueryFun = ({ deviceId, token }) => {
  return api({
    url: `/auth/verify_device_id?device_id=${deviceId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

//@Query
export const useGetVerifyDevice = ({ deviceId, token }) => {
  return useQuery({
    queryKey: ["get-verify-device", deviceId],
    queryFn: () => getVerifyDeviceQueryFun({ deviceId, token }),
  });
};

const IsAuthenticated = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { divesId, token } = useSelector((state) => state.Auth.userInfo);

  const { data } = useGetVerifyDevice({ deviceId: divesId, token });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Logout = () => {
    navigate("/");
    dispatch(changeUser({}));
    dispatch(changeToken(""));
    dispatch(changePhone(""));
    dispatch(changeCode(""));
    dispatch(changeCodeVerify(""));
    dispatch(changeFcmToken(""));
    dispatch(changeDataProfile({}));
    //dispatch(changeDivesId(""));
    window.location.reload();
  };

  useEffect(() => {
    if (data?.response?.status === 401) {
      toast.error(data?.response?.data?.message);
      setTimeout(() => {
        Logout();
      }, 2000);
    }
  }, [data]);
  return (
    <>
      <Outlet />
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default IsAuthenticated;
