import * as Style from "./Styled";
import * as images from "~/images";
import * as FormComp from "~/Components/Form";
import { useModalContext } from "../../../../../Provider/ModalProvider";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import {
  changeBackToHome,
  changeCode,
  changeCodeVerify,
  changeDataProfile,
  changeDivesId,
  changeFcmToken,
  changePhone,
  changeToken,
  changeUser,
} from "../../features/Auth";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const EndSition = () => {
  const dispatch = useDispatch();
  const { handleCloseEndSes, openLogin } = useModalContext();
  const [counter, setCounter] = useState(5);
  const navigate = useNavigate();
  const Logout = () => {
    // console.log("Logout II")
    navigate("/");
    dispatch(changeUser({}));
    dispatch(changeToken(""));
    dispatch(changePhone(""));
    dispatch(changeCode(""));
    dispatch(changeCodeVerify(""));
    dispatch(changeFcmToken(""));
    dispatch(changeDataProfile({}));
    //dispatch(changeDivesId(""))
    window.location.reload();
  };

  const handleLogin = () => {
    dispatch(changeBackToHome(true));
    handleCloseEndSes();
    openLogin();
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((pre) => {
        if (pre > 0) return pre - 1;
        return pre;
      });
    }, 1000);

    const timeoutId = setTimeout(() => {
      Logout();
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Style.MsgParents>
      <Style.Image src={images.Lock} />
      <Style.TextMassage>{/* انتهت صلاحية الجلسة */}</Style.TextMassage>
      <Box sx={{ display: "flex", gap: "2rem" }}>
        <FormComp.MuiButton
          text={`انتقل الى الرئيسة ${counter}`}
          width="200px"
          onClick={() => [handleCloseEndSes(), Logout()]}
        />
        <FormComp.MuiButton
          text={`تسجيل الدخول`}
          width="200px"
          onClick={() => [handleLogin()]}
        />
      </Box>
    </Style.MsgParents>
  );
};

export default EndSition;
